import find from 'lodash/find'
/**
 * Enum for Location Naming Type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const locationNamingTypeEnum = Object.freeze({
  UNDEFINED: { key: 'UNDEFINED', value: null, text: 'Select' },
  NUMBER: { key: 'NUMBER', value: 'NUMBER', text: 'Number 0-9' },
  LETTER: { key: 'LETTER', value: 'LETTER', text: 'Letter A-Z' },
})

function getLocationNamingTypeEnum(predicate, predicateKey = 'key') {
  const result = find(locationNamingTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'UNDEFINED', value: null, text: 'Select' }
}

function getLocationNamingTypeEnumList() {
  const options = []
  Object
    .keys(locationNamingTypeEnum)
    .map(key => options.push(
      {
        value: locationNamingTypeEnum[key].value,
        text: locationNamingTypeEnum[key].text,
      },
    ))
  return options
}

export default locationNamingTypeEnum

export {
  getLocationNamingTypeEnum,
  getLocationNamingTypeEnumList,
}
