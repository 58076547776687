<template>
  <div>
    <ul class="nav nav-pills">
      <li class="nav-item">
        <dx-util-button
            type="default"
            icon="plus"
            hint="Add Location"
            @click="onEmitAddLocation"
          />
      </li>
      <li class="nav-item">
        <button class="nav-link" @click="getLocation()">
          <i class="bi bi-house-fill" /> {{ userWarehouseName }}
        </button>
      </li>
      <li v-for="item, index in navigation" :key="item.id" class="nav-item">
        <button class="nav-link" :class="navigation.length-1===index ? 'active': ''" @click="onClickOut(item, index)">
          {{ item.name }}
        </button>
      </li>
      <li class="nav-item ml-auto">
        <button class="nav-link" @click="onClickPrintLevel">
          Print Level
        </button>
      </li>
    </ul>
    <div class="card border-secondary mb-0">
      <div class="card-body p-1">
        <div class="row">
          <div v-for="item in locations" :key="item.id" :class="isListMode ? 'col-xl-12': 'col-xl-4 col-lg-6 col-md-12'" @dblclick="onClickIn(item)">
            <div class="card card-browser-states mb-half border-secondary" style="background-color: #8088a0">
              <div class="card-body p-1">
                <div class="browser-states p-0 mt-0">
                  <div class="media">
                    <span class="b-avatar rounded-circle mr-1" style="width: 48px; height: 48px;" :class="setCardClass(item)">
                      <span class="b-avatar-custom">
                        <div class="location-icon"></div>
                      </span>
                    </span>
                    <h6 class="align-self-center my-auto">
                      <span :id="`location-${item.id}`" class="btn btn-sm btn-info" style="padding-left: 6px !important; padding-right: 6px !important;" @click="onClickEditLocationName(item)">
                        <i class="bi bi-pencil-square" style="font-size: 1rem;"></i>
                      </span>
                      {{ item.type }} {{ item.location }}
                      <dx-tooltip
                        :target="`#location-${item.id}`"
                        :position="'top'"
                        :animation="animationConfig"
                        show-event="dxhoverstart"
                        hide-event="dxhoverend"
                        :close-on-outside-click="false"
                      >
                        Edit Location Name
                      </dx-tooltip>
                    </h6>
                  </div>
                  <div class="d-flex align-items-center" style="position: relative;">
                    <dx-util-button-group
                      :items="getActionButtons(item)"
                      key-expr="event"
                      selection-mode="none"
                      styling-mode="contained"
                      @item-click="itemClick($event, item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isListMode" class="col-xl-12 px-0">
            <item-location
              :component-id="itemLocationComponentId"
              :query="searchItemQuery"
              :divider="1.6"
              :actions="false"
              :show-header="false"
              @on-hide-search-item-component="onEmitHideItemLocationComponent"
            />
          </div>
        </div>
      </div>
    </div>
    <location-nodes :component-id="locationNodeComponentId" :current-location-id="currentLocationId" />
    <print-location :component-id="printLocationComponentId" :items="currentLevelLocations" :parent-item="parentItem" />
    <!--Begin: Edit Location Name-->
    <dx-util-popup
      ref="popupEditLocationNameRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      title="Edit Location Name"
      :width="480"
      :height="240"
      content-template="popup-content"
      @hidden="onHiddenPopupEditLocationName"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveEditLocationNameButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelEditLocationNameButtonOptions" />
      <template #popup-content>
          <div class="form-group">
            <label for="locationName">Location Name</label>
            <input id="locationName" v-model="selectedLocation.location" type="text" class="form-control">
          </div>
      </template>
    </dx-util-popup>
    <!--End: Edit Location Name-->
  </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'
import warehouseLocationService from '@/http/requests/warehouseLocation/warehouseLocationService'
import { v4 as uuidv4 } from 'uuid'
import locationTypeEnum, { getLocationTypeEnum } from '@/enums/location-type.enum'
import { Notify } from '@robustshell/utils/index'
import { last } from 'lodash'
import { DxTooltip } from 'devextreme-vue/tooltip'
import eventEnum from './event.enum'
import LocationNodes from './LocationNodes.vue'
import PrintLocation from './PrintLocation.vue'
import ItemLocation from './ItemLocation.vue'

export default {
  components: {
    LocationNodes,
    PrintLocation,
    ItemLocation,
    DxTooltip,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: locationTypeEnum.ZONE.value,
    },
  },
  setup() {
    const {
      userWarehouseId,
      userWarehouseName,
    } = useCurrentUser()
    return {
      userWarehouseName,
      userWarehouseId,
    }
  },
  data() {
    return {
      parent: 124793667,
      currentLevelLocations: [],
      locations: [],
      navigation: [],
      actionButtons: [],
      isListMode: false,
      locationNodeComponentId: null,
      currentLocationId: null,
      currentSelectedLocation: null,
      printLocationComponentId: null,
      searchItemQuery: '',
      itemLocationComponentId: '',
      parentItem: null,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0.1,
            opacity: 0,
          },
          to: {
            scale: 1,
            opacity: 1,
          },
        },
        hide: {
          type: 'pop',
          from: {
            scale: 1,
            opacity: 1,
          },
          to: {
            scale: 0.1,
            opacity: 0,
          },
        },
      },
      popupEditLocationNameTitle: '',
      selectedLocation: {
        id: null,
        location: '',
      },
    }
  },
  computed: {
    popupEditLocationNameInstance() {
      return this.$refs.popupEditLocationNameRef.instance
    },
    saveEditLocationNameButtonOptions() {
      return {
        text: 'Save',
        icon: 'save',
        onClick: e => {
          this.onClickSaveEditLocationName(e)
        },
        type: 'success',
      }
    },
    cancelEditLocationNameButtonOptions() {
      return {
        text: 'Cancel',
        icon: 'close',
        onClick: () => {
          this.popupEditLocationNameInstance.hide()
        },
        type: 'danger',
      }
    },
  },
  watch: {
    async componentId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        await this.getLocation()
      }
    },
  },
  async mounted() {
    await this.getLocation()
  },
  methods: {
    // #region Edit Location Name
    onClickEditLocationName(item) {
      this.selectedLocation = {
        id: item.id,
        location: item.location,
      }
      this.popupEditLocationNameInstance.show()
    },
    async onClickSaveEditLocationName(e) {
      const { id, location } = this.selectedLocation
      if (!id || !location) {
        Notify.warn('Please enter location name')
        return
      }
      await warehouseLocationService.updateLocationName(id, location)
      Notify.success('Location name updated successfully')
      this.popupEditLocationNameInstance.hide()
      await this.getLocation()
    },
    onHiddenPopupEditLocationName() {
      this.selectedLocation = {
        id: null,
        location: '',
      }
    },
    // #endregion
    setCardClass(item) {
      const locationType = getLocationTypeEnum(item.type)
      if (locationType.key === locationTypeEnum.ZONE.key) return 'card-zone'
      if (locationType.key === locationTypeEnum.AISLE.key) return 'card-aisle'
      if (locationType.key === locationTypeEnum.RACK.key) return 'card-rack'
      if (locationType.key === locationTypeEnum.SHELF.key) return 'card-shelf'
      if (locationType.key === locationTypeEnum.POSITION.key) return 'card-position'
      if (locationType.key === locationTypeEnum.BIN.key) return 'card-bin'
      return ''
    },
    getActionButtons(item) {
      return [
        {
          icon: 'movetofolder',
          event: eventEnum.NEXT_LEVEL.value,
          hint: eventEnum.NEXT_LEVEL.text,
          visible: item.type !== locationTypeEnum.BIN.value,
        },
        {
          icon: 'map',
          event: eventEnum.LIST_NODE.value,
          hint: eventEnum.LIST_NODE.text,
          visible: true,
        },
        {
          icon: 'print',
          event: eventEnum.PRINT.value,
          hint: eventEnum.PRINT.text,
          visible: true,
        },
        {
          icon: 'plus',
          event: eventEnum.ADD_CHILD.value,
          hint: eventEnum.ADD_CHILD.text,
          visible: item.type !== locationTypeEnum.BIN.value,
        },
        {
          icon: 'checklist',
          event: eventEnum.LIST.value,
          hint: eventEnum.LIST.text,
          visible: item.queryable,
        },
        {
          icon: 'export',
          event: eventEnum.MOVE.value,
          hint: eventEnum.MOVE.text,
          visible: item.movable,
        },
        {
          icon: 'trash',
          event: eventEnum.DELETE.value,
          hint: eventEnum.DELETE.text,
          visible: true,
        },
      ]
    },
    formatLocationName(location) {
      return location.padEnd(6, '0')
    },
    async itemClick(e, item) {
      if (e.itemData.event === eventEnum.NEXT_LEVEL.key) {
        this.onClickIn(item)
        return
      }
      if (e.itemData.event === eventEnum.LIST.key) {
        await this.onItemClickList(item)
        return
      }
      if (e.itemData.event === eventEnum.LIST_NODE.key) {
        await this.onItemClickListNodes(item)
      }
      if (e.itemData.event === eventEnum.ADD_CHILD.key) {
        this.onItemClickAddChild(item)
      }
      if (e.itemData.event === eventEnum.PRINT.key) {
        await this.onItemClickPrint(item)
      }
      if (e.itemData.event === eventEnum.DELETE.key) {
        await this.onItemClickDelete(item)
      }
    },
    async onItemClickList(item) {
      this.locations.length = 0
      this.locations.push(item)
      this.isListMode = true
      this.searchItemQuery = item.locName
      this.itemLocationComponentId = uuidv4()
    },
    async onItemClickListNodes(item) {
      this.currentLocationId = item.id
      this.locationNodeComponentId = uuidv4()
    },
    async getLocation() {
      const result = await warehouseLocationService.getFirstLevel(this.userWarehouseId)
      this.locations.length = 0
      this.locations = result
      this.navigation.length = 0
    },
    async onItemClickPrint(item) {
      this.currentLevelLocations.length = 0
      this.currentLevelLocations.push(item)
      this.printLocationComponentId = uuidv4()
    },
    onItemClickAddChild(item) {
      const payload = {
        id: item.id,
        name: item.locName,
        type: item.type,
      }
      this.$emit('on-emit-add-location', payload)
    },
    async onItemClickDelete(item) {
      this.$swal({
        title: 'Are you sure you want to delete this location record?',
        text: 'Please be aware that ALL SUB-LOCATIONS under this location will also be deleted. Additionally, please note that once this location is deleted, all the items associated with this location will no longer be accessible through location search.',
        icon: 'error',
        input: 'text',
        inputPlaceholder: 'Type DELETE to confirm',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value === 'DELETE') {
          try {
            await warehouseLocationService.delete(item.id, true)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Location record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              const indexToRemove = this.locations.findIndex(obj => obj.id === item.id)
              this.locations.splice(indexToRemove, 1)
            })
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        } else {
          Notify.warn('Location not deleted. Please type DELETE to confirm')
        }
      })
    },
    onClickIn(parent) {
      this.parentItem = parent
      const locationType = getLocationTypeEnum(parent.type)
      if (locationType.hasChild) {
        warehouseLocationService.findByParent(parent.id).then(result => {
          this.locations = result.data
        }).then(() => {
          this.addNavigationItem(parent)
        })
      }
    },
    onClickOut(parent, index) {
      this.parentItem = parent
      warehouseLocationService.findByParent(parent.id).then(result => {
        this.locations = result.data
      }).then(() => {
        this.removeNavigationItem(index)
      }).then(() => {
        if (this.isListMode) {
          this.isListMode = !this.isListMode
          this.searchItemQuery = ''
          this.itemLocationComponentId = ''
        }
      })
    },
    addNavigationItem(item) {
      const name = `${item.type} ${item.locName}`
      this.navigation.push({
        id: item.id,
        name: name,
        type: item.type,
      })
    },
    removeNavigationItem(index) {
      const startIndex = index + 1
      this.navigation.splice(startIndex)
    },
    onClickPrintLevel() {
      this.currentLevelLocations.length = 0
      this.currentLevelLocations = this.locations
      this.printLocationComponentId = uuidv4()
    },
    onEmitHideItemLocationComponent() {
      this.isListMode = false
      this.searchItemQuery = ''
      this.itemLocationComponentId = ''
    },
    onEmitAddLocation() {
      const payload = {}
      if (this.navigation.length === 0) {
        payload.id = null
        payload.name = 'Warehouse'
        payload.type = locationTypeEnum.WAREHOUSE.value
      } else {
        const item = last(this.navigation)
        payload.id = item.id
        payload.name = item.name
        payload.type = item.type
      }
      this.$emit('on-emit-add-location', payload)
    },
  },
}
</script>

<style lang="scss">
  .card-zone, .card-aisle, .card-rack, .card-bin, .card-shelf, .card-position {
    background-color: rgba(251, 251, 251, 0.528);
    border: 2px solid #219ebc;
    cursor: pointer;
    .location-icon {
      height: 36px;
      width: 36px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .card-zone {
    border: 2px solid #219ebc !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/020-location.svg');
    }
  }
  .card-aisle {
    border-color: #C85200 !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/022-wooden-crate.svg')
    }
  }
  .card-rack {
    border-color: #CC8400 !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/006-storehouse.svg')
    }
  }
  .card-shelf {
     border-color: #254E24 !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/011-warehouse.svg')
    }
  }
  .card-position {
     border-color: #707E57 !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/003-box.svg')
    }
  }
  .card-bin {
     border-color: #AA6E2B !important;
    .location-icon {
      background-image: url('~@/assets/images/svg/warehouse/003-box.svg')
    }
  }
</style>
