<template>
    <!-- #region Add Location -->
    <dx-util-popup
    ref="printLocationPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="true"
    :title="printLocationPopupTitle"
    content-template="popup-content"
    @shown="onShownPrintLocationPopup"
    >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="printLocationDownloadButtonOptions" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="printLocationCancelButtonOptions" />
    <template #popup-content>
        <dx-util-scroll-view width="100%" height="100%">
          <div class="row">
          <div class="form-group col-6 align-self-center">
            <dx-util-select-box
              v-model="selectedBarcodeTemplate"
              :data-source="templateSettings"
              display-expr="templateName"
              value-expr="id"
              @value-changed="onChangeSelectedTemplate"
            />
          </div>
          <div class="form-group col-2 align-self-center">
            <dx-util-check-box
              v-model="onlyDeepestLevels"
              label="Bracode Template"
              label-mode="floating"
              text="Print Only Deepest Levels"
            />
          </div>
          <div class="form-group col-2 align-self-center">
            <dx-util-button
              text="Get Location Level"
              type="success"
              class="w-100"
              @click="getLocationLevel"
            />
          </div>
          <div class="form-group col-2 align-self-center">
            <dx-util-button
              text="Print Current Level"
              type="Default"
              class="w-100"
              @click="onPrintCurrentLevel"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <svg v-for="item in printableItems" :id="`barcodePreview${item.id}`" :key="item.id" :ref="`barcodePreview${item.id}Ref`" class="mr-half ml-1 mt-1"></svg>
          </div>
        </div>
        </dx-util-scroll-view>
        </template>
    </dx-util-popup>
    <!-- #endregion -->
</template>
<script>
import { jsPDF } from 'jspdf'
import managedKeysEnum from '@/enums/managedKeysEnum'
import useCurrentUser from '@/libs/app/current-user'
import tenantService from '@/http/requests/tenant/tenan.settings'
import warehouseLocationService from '@/http/requests/warehouseLocation/warehouseLocationService'

const JsBarcode = require('jsbarcode')

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    parentItem: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const {
      userCompanyId,
      userCompanyType,
      userWarehouseId,
      userWarehouseName,
    } = useCurrentUser()
    return {
      userCompanyId,
      userCompanyType,
      userWarehouseId,
      userWarehouseName,
    }
  },
  data() {
    return {
      JsBarcode,
      templateSettings: [],
      printLocationPopupTitle: '',
      selectedBarcodeTemplate: 0,
      onlyDeepestLevels: true,
      printableItems: [],
    }
  },
  computed: {
    addLocationPopupIns() {
      const popup = this.$refs.printLocationPopupRef.instance
      return popup
    },
    locationNamingOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        valueExpr: 'value',
        displayExpr: 'text',
        items: this.locationNamingTypes,
        acceptCustomValue: false,
        inputAttrs: {
          autocomplete: 'off',
        },
      }
    },
    printLocationDownloadButtonOptions() {
      return {
        text: 'Download',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleAddLocation(e)
        },
      }
    },
    printLocationCancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePrintLocationPopup()
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createPrintLocationPopup()
          this.printableItems = [...this.items]
        }
      },
    },
  },
  methods: {
    /* #region Add New Location */
    createPrintLocationPopup() {
      this.addLocationPopupIns.show()
    },
    onShownPrintLocationPopup() {
      this.printLocationPopupTitle = 'Download Location Barcodes'
      this.getTemplateTenantSettings()
    },
    closePrintLocationPopup() {
      this.addLocationPopupIns.hide()
      this.selectedBarcodeTemplate = 0
      this.onlyDeepestLevels = true
      this.printableItems.length = 0
      this.templateSettings.length = 0
    },
    async handleAddLocation() {
      // eslint-disable-next-line new-cap
      await this.download()
      this.closePrintLocationPopup()
    },
    async getLocationLevel() {
      const result = await warehouseLocationService.getDeepestLevel({
        parentId: this.parentItem.id,
        onlyDeepestLevels: this.onlyDeepestLevels,
      })
      this.printableItems.length = 0
      this.printableItems = [...result]
      const barcodeOptions = this.getSelectedBarcodeOptions()
      this.$nextTick(async () => {
        this.printableItems.forEach(item => {
          const targetRef = `barcodePreview${item.id}Ref`
          const target = this.$refs[targetRef][0]
          barcodeOptions.text = item.locName
          const barcodeText = item.locName
          this.JsBarcode(`#${target.id}`, barcodeText, barcodeOptions)
        })
      })
    },
    onPrintCurrentLevel() {
      this.printableItems.length = 0
      this.printableItems = [...this.items]
      const barcodeOptions = this.getSelectedBarcodeOptions()
      this.$nextTick(async () => {
        this.printableItems.forEach(item => {
          const targetRef = `barcodePreview${item.id}Ref`
          const target = this.$refs[targetRef][0]
          barcodeOptions.text = item.locName
          const barcodeText = item.locName
          this.JsBarcode(`#${target.id}`, barcodeText, barcodeOptions)
        })
      })
    },
    async download() {
      const heightOptions = 27
      let imageWidth = 0
      let imageHeight = 0

      for (let i = 0; i < this.printableItems.length; ++i) {
        const item = this.printableItems[i]
        const itemTargetRef = `barcodePreview${item.id}Ref`
        const itemTargetId = this.$refs[itemTargetRef][0]
        const itemTarget = document.getElementById(itemTargetId.id)
        const imageWidth1 = itemTarget.width.animVal.value
        const imageHeight1 = itemTarget.height.animVal.value
        imageWidth = Math.max(imageWidth, imageWidth1)
        imageHeight = Math.max(imageHeight, imageHeight1)
      }

      const orientation = imageWidth > imageHeight ? 'l' : 'p'
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: orientation,
        unit: 'px',
        format: [imageWidth, imageHeight + heightOptions],
        hotfixes: ['px_scaling'],
        precision: 0,
        userUnit: 72,
        margins: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      })

      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight() - heightOptions

      const ratio = Math.min(pageWidth / imageWidth, pageHeight / imageHeight)
      const w = imageWidth * ratio
      const h = imageHeight * ratio

      for (let i = 0; i < this.printableItems.length; ++i) {
        const item = this.printableItems[i]
        const itemTargetRef = `barcodePreview${item.id}Ref`
        const itemTargetId = this.$refs[itemTargetRef][0]
        const itemTarget = document.getElementById(itemTargetId.id)
        const svgAsText = new XMLSerializer().serializeToString(itemTarget)

        doc.setFontSize(11)
        if (i > 0) {
          doc.addPage({
            format: [imageWidth, imageHeight],
          })
        }
        // eslint-disable-next-line no-await-in-loop
        await doc.addSvgAsImage(svgAsText, 0, 0, w, h)
      }
      doc.save('barcodes.pdf')
    },
    /* #endregion */

    getTemplateTenantSettings() {
      tenantService.findByKeyAndCurrentTenantId(managedKeysEnum.BARCODE_TEMPLATE.key)
        .then(result => {
          const { data } = result
          this.templateSettings.length = 0
          data.forEach(item => {
            const value = JSON.parse(item.value)
            const { templateName, templateSettings } = value
            const setting = {
              id: item.id,
              templateName: templateName,
              barcodeOptions: templateSettings,
            }
            this.templateSettings.push(setting)
          })
        })
        .then(() => {
          this.selectedBarcodeTemplate = this.templateSettings[0].id
        })
    },

    onChangeSelectedTemplate() {
      const barcodeOptions = this.getSelectedBarcodeOptions()
      this.$nextTick(async () => {
        this.printableItems.forEach(item => {
          const targetRef = `barcodePreview${item.id}Ref`
          const target = this.$refs[targetRef][0]
          barcodeOptions.text = item.locName
          const barcodeText = item.locName
          this.JsBarcode(`#${target.id}`, barcodeText, barcodeOptions)
        })
      })
    },
    getSelectedBarcodeOptions() {
      const selectedTheme = this.templateSettings.find(item => item.id === this.selectedBarcodeTemplate)
      const { barcodeOptions } = selectedTheme
      return barcodeOptions
    },
  },
}
</script>

<style>

</style>
