<template>
  <div class="container-fluid">
    <div class="row gutter-5 ">
      <div class="col-12">
        <div class="card" :style="{ height: pageHeight}">
          <div class="card-body my-0 p-1">
            <div class="row">
              <div class="col-12">
                  <parts
                    :component-id="partsComponentId"
                    @on-emit-add-location="onClickAddNewLocation"
                  />
                  <create-location
                    :component-id="createLocationComponentId"
                    :selected-location="selectedLocation"
                    @on-emit-closed-add-location-popup="onEmitClosedAddLocationPopup"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import Parts from './components/TheParts.vue'
import CreateLocation from './components/CreateLocation.vue'

export default {
  components: {
    CreateLocation,
    Parts,
  },
  data() {
    return {
      pageHeight: '',
      selectedLocation: {},
      warehoueseTreeListSelectedRowKeys: [],
      warehoueseTreeListSelectedRows: [],
      warehoueseTreeListCurrentRootKey: null,
      createLocationComponentId: '',
      partsComponentId: '',
    }
  },
  computed: {
  },
  methods: {
    onClickAddNewLocation(payload) {
      this.selectedLocation = payload
      this.createLocationComponentId = uuidv4()
    },
    onEmitClosedAddLocationPopup() {
      this.partsComponentId = uuidv4()
    },
  },
}
</script>

<style></style>
