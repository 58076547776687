<template>
    <dx-util-popup
    ref="locationNodePopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    :title="locationNodePopupTitle"
    content-template="popup-content"
    @shown="onShownLocationNodePopup"
    @hiding="onHidingLocationNodePopup"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
    <template #popup-content>
      <dx-util-scroll-view width="100%" height="100%">
        <div class="row">
          <div class="col-12">
            <app-timeline>
              <app-timeline-item v-for="item in locations" :key="item.id" icon="BoxIcon">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{ item.type }}</h6>
                </div>
                <div class="d-flex flex-column flex-wrap justify-content-start">
                  <p class="mb-0">
                    {{ item.locName }}
                  </p>
                </div>
              </app-timeline-item>
            </app-timeline>
          </div>
        </div>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
</template>

<script>
import warehouseLocationService from '@/http/requests/warehouseLocation/warehouseLocationService'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    currentLocationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      locations: [],
      locationNodePopupTitle: '',
    }
  },
  computed: {
    popupIns() {
      const popup = this.$refs.locationNodePopupRef.instance
      return popup
    },
    cancelButtonOptions() {
      return {
        text: 'Close',
        type: 'danger',
        onClick: () => {
          this.onCloseLocationNodePopup()
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createLocationNodePopup()
        }
      },
    },
  },
  methods: {
    createLocationNodePopup() {
      this.popupIns.show()
    },
    async onShownLocationNodePopup() {
      this.locationNodePopupTitle = 'Location Node'
      await this.getLocationRootPath()
    },
    onHidingLocationNodePopup() {
      this.locationNodePopupTitle = ''
      this.locations.length = 0
    },
    onCloseLocationNodePopup() {
      this.popupIns.hide()
    },
    async getLocationRootPath() {
      const data = await warehouseLocationService.findRootPath(this.currentLocationId)
      this.locations = data
    },
  },
}
</script>

<style>

</style>
