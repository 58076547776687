<template>
    <!-- #region Add Location -->
    <dx-util-popup
    ref="addLocationPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    :title="addLocationPopupTitle"
    content-template="popup-content"
    @shown="onShownAddLocationPopup"
    >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="addLocationSaveButtonOptions" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="addLocationCancelButtonOptions" />
    <template #popup-content>
        <dx-util-scroll-view width="100%" height="100%">
        <form onsubmit="return false;">
            <dx-util-form
            ref="addLocationFormRef"
            :form-data="locationFormData"
            :col-count="2"
            :show-colon-after-label="true"
            label-location="top"
            >
            <dx-util-simple-item data-field="zoneNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showZone">
                <dx-util-required-rule message="Zone Naming Type field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="zoneCount" editor-type="dxNumberBox" :visible="showZone">
                <dx-util-required-rule message="Zone Count field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="aisleNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showAisle">
                <dx-util-required-rule message="Aisle Naming Type field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="aisleCount" editor-type="dxNumberBox" :visible="showAisle">
                <dx-util-required-rule message="Aisle Count field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="rackNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showRack">
                <dx-util-required-rule message="Rack Naming Type field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="rackCount" editor-type="dxNumberBox" :visible="showRack">
                <dx-util-required-rule message="Rack Count field is required" />
            </dx-util-simple-item>
            <dx-util-simple-item data-field="shelfNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showShelf" />
            <dx-util-simple-item data-field="shelfCount" editor-type="dxNumberBox" :visible="showShelf" />
            <dx-util-simple-item data-field="positionNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showPosition" />
            <dx-util-simple-item data-field="positionCount" editor-type="dxNumberBox" :visible="showPosition" />
            <dx-util-simple-item data-field="binNamingType" editor-type="dxSelectBox" :editor-options="locationNamingOptions" :visible="showBin" />
            <dx-util-simple-item data-field="binCount" editor-type="dxNumberBox" :visible="showBin" />
            </dx-util-form>
        </form>
        </dx-util-scroll-view>
        </template>
    </dx-util-popup>
    <!-- #endregion -->
</template>
<script>
import useCurrentUser from '@/libs/app/current-user'
import warehouseLocationService from '@/http/requests/warehouseLocation/warehouseLocationService'
import locationNamingTypeEnum, { getLocationNamingTypeEnumList } from '@/enums/location-naming-type.enum'
import locationTypeEnum from '@/enums/location-type.enum'
import { Notify } from '@robustshell/utils/index'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    selectedLocation: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      userCompanyId,
      userCompanyType,
      userWarehouseId,
      userWarehouseName,
    } = useCurrentUser()
    return {
      userCompanyId,
      userCompanyType,
      userWarehouseId,
      userWarehouseName,
    }
  },
  data() {
    return {
      locationFormData: {
        companyId: this.userCompanyId,
        warehouseId: this.userWarehouseId,
        parentType: locationTypeEnum.WAREHOUSE.value,
        parentId: null,
        zoneNamingType: locationNamingTypeEnum.UNDEFINED.value,
        zoneCount: 0,
        aisleNamingType: locationNamingTypeEnum.UNDEFINED.value,
        aisleCount: 0,
        rackNamingType: locationNamingTypeEnum.UNDEFINED.value,
        rackCount: 0,
        shelfNamingType: locationNamingTypeEnum.UNDEFINED.value,
        shelfCount: 0,
        positionNamingType: locationNamingTypeEnum.UNDEFINED.value,
        positionCount: 0,
        binNamingType: locationNamingTypeEnum.UNDEFINED.value,
        binCount: 0,
      },
      addLocationPopupTitle: '',
      locationNamingTypes: getLocationNamingTypeEnumList(),
    }
  },
  computed: {
    addLocationPopupIns() {
      const popup = this.$refs.addLocationPopupRef.instance
      return popup
    },
    locationNamingOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        valueExpr: 'value',
        displayExpr: 'text',
        items: this.locationNamingTypes,
        acceptCustomValue: false,
        inputAttrs: {
          autocomplete: 'off',
        },
      }
    },
    addLocationSaveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleAddLocation(e)
        },
      }
    },
    addLocationCancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closeAddLocationPopup()
        },
      }
    },
    showZone() {
      return this.selectedLocation.type === locationTypeEnum.WAREHOUSE.value
    },
    showAisle() {
      if (this.showZone) {
        return true
      }
      return this.selectedLocation.type === locationTypeEnum.ZONE.value
    },
    showRack() {
      if (this.showZone || this.showAisle) {
        return true
      }
      return this.selectedLocation.type === locationTypeEnum.AISLE.value
    },
    showShelf() {
      if (this.showZone || this.showAisle || this.showRack) {
        return true
      }
      return this.selectedLocation.type === locationTypeEnum.RACK.value
    },
    showPosition() {
      if (this.showZone || this.showAisle || this.showRack || this.showShelf) {
        return true
      }
      return this.selectedLocation.type === locationTypeEnum.SHELF.value
    },
    showBin() {
      if (this.showZone || this.showAisle || this.showRack || this.showShelf || this.showPosition) {
        return true
      }
      return this.selectedLocation.type === locationTypeEnum.POSITION.value
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.dataSourceArr = []
          this.createAddNewLocationPopup()
        }
      },
    },
  },
  methods: {
    /* #region Add New Location */
    createAddNewLocationPopup() {
      this.addLocationPopupIns.show()
    },
    onShownAddLocationPopup() {
      this.addLocationPopupTitle = `${this.userWarehouseName} / Add Warehouse Location`
    },
    closeAddLocationPopup() {
      this.addLocationPopupIns.hide()
      this.locationFormData = {
        companyId: this.userCompanyId,
        warehouseId: this.userWarehouseId,
        zoneNamingType: locationNamingTypeEnum.UNDEFINED.value,
        zoneCount: 0,
        aisleNamingType: locationNamingTypeEnum.UNDEFINED.value,
        aisleCount: 0,
        rackNamingType: locationNamingTypeEnum.UNDEFINED.value,
        rackCount: 0,
        shelfNamingType: locationNamingTypeEnum.UNDEFINED.value,
        shelfCount: 0,
        positionNamingType: locationNamingTypeEnum.UNDEFINED.value,
        positionCount: 0,
        binNamingType: locationNamingTypeEnum.UNDEFINED.value,
        binCount: 0,
      }
      this.$emit('on-emit-closed-add-location-popup')
    },
    async handleAddLocation() {
      const form = this.$refs.addLocationFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        try {
          this.locationFormData.parentId = this.selectedLocation.id
          this.locationFormData.parentType = this.selectedLocation.type
          await warehouseLocationService.createBulk(this.locationFormData)
          Notify.success('Warehouse Locations have been created successfully')
        } catch (err) {
          const messeage = err.message || err
          Notify.error(messeage)
        }
        this.closeAddLocationPopup()
      }
    },
    /* #endregion */
  },
}
</script>

<style>

</style>
