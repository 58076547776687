const eventEnum = Object.freeze({
  PRINT: { key: 'print', value: 'print', text: 'Print Label' },
  LIST: { key: 'list', value: 'list', text: 'List Items' },
  MOVE: { key: 'move', value: 'move', text: 'Change Patent Location' },
  ADD_CHILD: { key: 'addChild', value: 'addChild', text: 'Add Child Location' },
  NEEDS_ACTION: { key: 'needs_action', value: 'needs_action', text: 'Needs Action' },
  LIST_NODE: { key: 'list_node', value: 'list_node', text: 'List Nodes' },
  NEXT_LEVEL: { key: 'next_level', value: 'next_level', text: 'Next Level' },
  DELETE: { key: 'delete', value: 'delete', text: 'Delete Location' },
})

export default eventEnum
