import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all WarehouseLocation records
  async fetchAll(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get WarehouseLocation record for given id
  async fetchById(id) {
    const response = await axios.get(endpoints.warehouseLocation.Controller.findById(id))
    return response.data.body
  },
  async findRootPath(id) {
    const response = await axios.get(endpoints.warehouseLocation.Controller.findRootPath(id))
    return response.data.body
  },
  async getFirstLevel(id) {
    const response = await axios.get(endpoints.warehouseLocation.Controller.getFirstLevel(id))
    return response.data.body
  },
  async getDeepestLevel(data) {
    const response = await axios.post(endpoints.warehouseLocation.Controller.getDeepestLevel(), data)
    return response.data.body
  },
  async updateLocationName(id, location) {
    const response = await axios.put(endpoints.warehouseLocation.Controller.updateLocationName(id, location))
    return response.data.body
  },
  // get WarehouseLocation record for given location
  async findByLocation(location) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findByLocation(location))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get WarehouseLocation records for given parent
  async findByParent(parentId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findByParent(parentId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get WarehouseLocation records for given parent
  async findByParentLevel(parentId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findByParentLevel(parentId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get WarehouseLocation records for given type
  async findByType(locationType) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findByType(locationType))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Get WarehouseLocation records tree with all sub locations for given id. We run BFS algorithm to construct a location tree, id as the root node
  async findAllSubLocationsById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouseLocation.Controller.findAllSubLocationsById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create warehouseLocation
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.warehouseLocation.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create bulk warehouse location
  async createBulk(data) {
    const response = await axios.post(endpoints.warehouseLocation.Controller.createBulk(), data)
    return response
  },
  // update warehouseLocation
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.warehouseLocation.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update parent location
  async setParentLocation(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.warehouseLocation.Controller.setParentLocation(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete warehouseLocation
  async deleteMany(ids) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.warehouseLocation.Controller.deleteMany(), ids)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async delete(id, accepted) {
    const result = await axios.delete(endpoints.warehouseLocation.Controller.delete(id, accepted))
    return result
  },
}
