import find from 'lodash/find'
/**
 * Enum for Location Naming Type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const locationTypeEnum = Object.freeze({
  WAREHOUSE: {
    key: 'WAREHOUSE', value: 'WAREHOUSE', text: 'Warehouse', parent: null, hasChild: true, movable: false, queryable: false,
  },
  ZONE: {
    key: 'ZONE', value: 'ZONE', text: 'Zone', parent: null, hasChild: true, movable: false, queryable: false, pathText: 'Z',
  },
  AISLE: {
    key: 'AISLE', value: 'AISLE', text: 'Aisle', parent: null, hasChild: true, movable: false, queryable: false, pathText: 'Z-A',
  },
  RACK: {
    key: 'RACK', value: 'RACK', text: 'Rack', parent: null, hasChild: true, movable: true, queryable: true, pathText: 'Z-A-R',
  },
  SHELF: {
    key: 'SHELF', value: 'SHELF', text: 'Shelf', parent: null, hasChild: true, movable: true, queryable: true, pathText: 'Z-A-R-S',
  },
  POSITION: {
    key: 'POSITION', value: 'POSITION', text: 'Position', parent: null, hasChild: true, movable: true, queryable: true, pathText: 'Z-A-R-S-P',
  },
  BIN: {
    key: 'BIN', value: 'BIN', text: 'Bin', parent: null, hasChild: false, movable: true, queryable: true, pathText: 'Z-A-R-S-P-B',
  },
})

function getLocationTypeEnum(predicate, predicateKey = 'key') {
  const result = find(locationTypeEnum, { [predicateKey]: predicate })
  return result || locationTypeEnum.WAREHOUSE
}

function getLocationTypeEnumList() {
  const options = []
  Object
    .keys(locationTypeEnum)
    .map(key => options.push(locationTypeEnum[key]))
  return options
}

export default locationTypeEnum

export {
  getLocationTypeEnum,
  getLocationTypeEnumList,
}
